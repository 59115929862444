import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from "./styles.module.scss";
import Triangle from "./icons/triangle";
import Plate from "./icons/plate";
import Star1 from "./icons/star1";
import Star3 from "./icons/star3";
import Star2 from "./icons/star2";
import {random} from "lodash";
import {getRandomFactors} from "../../../helpers/dom/getRandomFactors";
import {elementsParallax} from "../../../helpers/dom/elementsParallax";
import {isTouchScreen} from "../../../helpers/dom/isTouchScreen";
import Ghost from "./icons/ghost";
import useTranslation from "next-translate/useTranslation";
import {COMMON_NAMESPACE} from "../../../utils/i18nNameSpaces";

const Index = () => {
  const starsRef = useRef([]);
  const [isTouchScreenDevice, setIsTouchScreen] = useState()
  const {t} = useTranslation(COMMON_NAMESPACE);

  const showStars = useMemo(() => {
    const starsArr = [
      <Star1/>,
      <Star3/>,
      <Star2/>,
      <Star1/>,
      <Star3/>,
      <Star1/>,
      <Star3/>,
      <Star2/>,
      <Star2/>,
      <Star3/>,
      <Star1/>,
      <Star2/>,
      <Star2/>,
      <Star3/>,
      <Star1/>,
      <Star2/>,
      <Star2/>,
      <Star3/>,
      <Star1/>,
      <Star2/>,
      <Star2/>,
      <Star3/>,
      <Star1/>
    ]

    const addToRefs = (element) => {
      if (element && !starsRef.current.includes(element)) {
        starsRef.current.push(element);
      }
    }

    return (
      <div>
        {starsArr.map((el, i) => (
          <div className={styles["star"]} ref={addToRefs} key={i} style={{
            top: `${random(0.0, 95.0, true)}%`,
            left: `${random(0.0, 100.0, true)}%`
          }}>
            {el}
          </div>
        ))}
      </div>
    )
  }, [starsRef])

  const factors = getRandomFactors(starsRef.current.length)
  const heroMouseMove = useCallback((e) => {
    if (isTouchScreenDevice) {
      return;
    }

    elementsParallax(e, starsRef.current, factors);
  }, [isTouchScreenDevice]);

  useEffect(() => {
    setIsTouchScreen(isTouchScreen());
  }, [])

  return (
    <div className={styles["wrapper"]} onMouseMove={heroMouseMove}>
      <div className={styles["container"]}>
        <div className={styles["content"]}>
          <div className={styles["subhead"]}>404</div>
          <div className={styles["header"]}>{t("404")}</div>
        </div>
        <div className={styles["picture-wrapper"]}>
          <div className={styles["alien"]}>
            <div className={styles["plate"]}>
              <Plate/>
            </div>
            <div className={styles["triangle"]}>
              <Triangle/>
            </div>
            <div className={styles["ghost"]}>
              <Ghost/>
            </div>
          </div>
          <div>
            {showStars}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;