import React from 'react';

const Ghost = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="93" height="115" viewBox="0 0 93 115" fill="none">
        <path d="M26.7798 112.723C28.7022 110.087 32.0004 108.242 35.1604 108.96C38.3204 109.679 41.3158 112.69 44.1269 111.181C47.9716 109.125 45.2461 101.566 51.4871 101.006C52.7876 100.92 54.0899 101.146 55.2857 101.665C57.919 102.739 62.0401 103.952 64.0678 101.533C65.2594 100.11 65.1738 97.9418 66.3127 96.4657C67.6294 94.7523 70.1376 94.5348 72.2772 94.8643L72.3826 94.8972C76.5103 96.6041 81.0265 93.7703 80.7039 89.3155C80.2891 83.3844 82.172 77.796 82.5801 76.5834L90.4011 53.3139C93.7278 43.3384 92.9742 32.4503 88.3049 23.0294C83.6357 13.6085 75.4307 6.42114 65.4832 3.03816C55.5188 -0.287574 44.6445 0.469801 35.2362 5.14482C25.8279 9.81983 18.6504 18.0324 15.2721 27.9882L2.94157 64.5829C-0.263206 74.2074 0.324843 84.6937 4.58552 93.8987C8.84619 103.104 16.4578 110.332 25.8647 114.107C26.1425 113.628 26.448 113.166 26.7798 112.723Z" fill="white"/>
        <path opacity="0.2" d="M41.3555 28.6208C43.7224 28.6208 45.6412 26.7 45.6412 24.3306C45.6412 21.9612 43.7224 20.0405 41.3555 20.0405C38.9885 20.0405 37.0697 21.9612 37.0697 24.3306C37.0697 26.7 38.9885 28.6208 41.3555 28.6208Z" fill="black"/>
        <path opacity="0.2" d="M65.727 36.8254C68.0939 36.8254 70.0127 34.9046 70.0127 32.5353C70.0127 30.1659 68.0939 28.2451 65.727 28.2451C63.36 28.2451 61.4412 30.1659 61.4412 32.5353C61.4412 34.9046 63.36 36.8254 65.727 36.8254Z" fill="black"/>
        <path d="M52.1529 46.1069C53.2847 42.7402 52.3837 39.3983 50.1404 38.6426C47.8971 37.8869 45.161 40.0035 44.0291 43.3702C42.8972 46.7369 43.7983 50.0788 46.0416 50.8345C48.2849 51.5903 51.021 49.4737 52.1529 46.1069Z" fill="#E0E0E0"/>
      </svg>
    </>
  );
};

export default Ghost;