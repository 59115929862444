import React from 'react';

const Star1 = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
        <path opacity="0.5" d="M4.45 8.42226C6.63153 8.42226 8.4 6.65197 8.4 4.46821C8.4 2.28445 6.63153 0.51416 4.45 0.51416C2.26848 0.51416 0.5 2.28445 0.5 4.46821C0.5 6.65197 2.26848 8.42226 4.45 8.42226Z" fill="#F55F44"/>
      </svg>
    </>
  );
};

export default Star1;