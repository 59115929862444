import React from 'react';

const Star3 = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <g opacity="0.5">
          <path opacity="0.5" d="M6.89166 0.204559H4.91669V11.4077H6.89166V0.204559Z" fill="#47E6B1"/>
          <path opacity="0.5" d="M11.5 6.79465V4.81763L0.30835 4.81763V6.79465L11.5 6.79465Z" fill="#47E6B1"/>
        </g>
      </svg>
    </>
  );
};

export default Star3;