import React from 'react';

const Star2 = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path opacity="0.5" d="M8.64425 5.09731C8.10034 4.85971 7.66246 4.43052 7.4137 3.89117C7.16493 3.35183 7.12256 2.73985 7.29466 2.17131C7.32229 2.08352 7.33997 1.99288 7.34734 1.90112C7.36414 1.64032 7.2963 1.38106 7.15396 1.16198C7.01163 0.942893 6.80239 0.775711 6.55743 0.685329C6.31246 0.594947 6.04489 0.586204 5.79456 0.660411C5.54424 0.734618 5.32454 0.887797 5.16824 1.09713C5.11552 1.17097 5.07136 1.25056 5.03658 1.33437C4.80038 1.87979 4.37179 2.31903 3.83262 2.56822C3.29346 2.81741 2.6815 2.85911 2.11358 2.68534C2.02569 2.65838 1.93522 2.64071 1.84366 2.63262C1.58313 2.6158 1.32415 2.68369 1.10529 2.82617C0.886426 2.96865 0.719405 3.17811 0.629115 3.42333C0.538826 3.66854 0.530092 3.9364 0.604223 4.18698C0.678354 4.43757 0.831376 4.65747 1.04049 4.81393C1.11321 4.87009 1.19288 4.91661 1.2775 4.95233C1.82142 5.18993 2.25929 5.61911 2.50806 6.15846C2.75683 6.6978 2.79917 7.30979 2.62708 7.87833C2.60007 7.96403 2.58242 8.05241 2.57442 8.14192C2.55601 8.40331 2.62267 8.66363 2.76444 8.88391C2.9062 9.10419 3.11544 9.27257 3.36081 9.36385C3.60618 9.45512 3.87448 9.46437 4.12555 9.39021C4.37661 9.31606 4.59693 9.16249 4.7535 8.9525C4.8076 8.87718 4.85184 8.79524 4.88516 8.70867C5.12719 8.16927 5.55824 7.73732 6.09683 7.49446C6.63542 7.25161 7.24422 7.21468 7.80816 7.39066C7.89605 7.41761 7.98651 7.43528 8.07808 7.44338C8.33861 7.4602 8.59759 7.39231 8.81645 7.24983C9.03531 7.10734 9.20233 6.89789 9.29262 6.65267C9.38291 6.40745 9.39165 6.1396 9.31751 5.88901C9.24338 5.63843 9.09036 5.41852 8.88124 5.26206C8.81007 5.19665 8.73034 5.14123 8.64425 5.09731Z" fill="#4D8AF0"/>
      </svg>
    </>
  );
};

export default Star2;