
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Page404 from "../components/sitePages/Page404";
import Head from "next/head";

export default function () {
  return (
    <>
      <Head>
        <title>Error 404 - DevIT.Software</title>
      </Head>
      <Page404/>
    </>

  )
}

    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  