const Triangle = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="419" height="363" viewBox="0 0 419 363" fill="none">
        <path opacity="0.2" d="M209.9 0.578552L314.417 181.793L418.934 363H209.9H0.866013L105.383 181.793L209.9 0.578552Z" fill="#2A7CC7"/>
      </svg>
    </>
  );
};

export default Triangle;