export function elementsParallax(e, elements, factors) {
  let offsetX = (e.clientX * 4.23 / window.innerWidth * 20);
  let offsetY = (e.clientY * 3.92 / window.innerHeight * 10);

  return elements.map((node, i) => {
    if (node.current) {
      node.current.style.transform = `translateX(${offsetX * factors[i]}px) translateY(${offsetY * factors[i] * -1.234532}px)`;
      return;
    }
    node.style.transform = `translateX(${offsetX * factors[i]}px) translateY(${offsetY * factors[i] * -1.234532}px)`;
  })
}